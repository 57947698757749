import { Route, Routes } from 'react-router-dom'
import Home from './components/home'
import About from './components/about'
import Contact from './components/contact'
import Layout from './components/layout'
import Portfolio from './components/portfolio'
import Dashboard from './components/dashboard'
import Resume from './components/resume'
import './App.scss'



function App() {
  return (
    <>
      <Routes>

      <Route index element={<Dashboard />} /> 
        <Route path="/" element={<Layout />}>
          
          <Route path="home" element={<Home />} />
          <Route path="aboutMe" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/resume" element={<Resume />} />
        </Route>
      </Routes>
    </>
  )
}

export default App