import AnimatedLetters from '../animatedLetters'
import './index.scss'
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import Me from '../../assets/images/me.jpg'

const About = () => {

    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return (
        <>
        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                <AnimatedLetters
                    letterClass={letterClass}
                    strArray={['A', 'b', 'o', 'u', 't', ' ', 'M', 'e']}
                    idx={15}
                />
                </h1>
                
                <p>
                    Hello, my name is Ryker Swensen. I am from St. Anthony, Idaho. I have always been
                    fascinated with technology from a young age. I grew up playing many video games 
                    and have watched the rapid growth of technology and accessibility of technology. 
                </p>
                <hr></hr>
                <p>
                    I am currently a student at Brigham Young University Idaho in Rexburg, Idaho. I 
                    have been fortunate enough to have taken great courses and learn alot! I
                    am anticipating my graduation with a Bachelor's Degree in Software Engineering in
                    December 2023.
                </p>
                <hr></hr>
                <p>
                    I have been lucky to not be alone throughout my academic career. Throughout 
                    my college years, I was lucky to gain a forever best friend, my dog Dood! I was also 
                    blessed with my Wife Corinne!
                </p>
            </div>
            <div className='stage-cube-cont'>
                
                <div className='cubespinner'>
                    <img src={Me} id="Me" alt="Me" width='75%'></img>
                </div>
            </div>
        </div>
        <Loader type="pacman" />
        </>
    )
}

export default About

// 