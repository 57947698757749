import { useEffect, useState } from 'react';
import LogoTitle from '../../assets/images/s2.png';
import LogoR from '../../assets/images/r2.png';
import { Link } from 'react-router-dom';
import './index.scss';
import AnimatedLetters from '../animatedLetters';
import '../animatedLetters'
import Logo from './logo'
import Loader from 'react-loaders';

const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const fnameArray = ['y', 'k', 'e', 'r']
    const nameArray = ['w', 'e', 'n', 's', 'e', 'n']
    const jobArray = ['F', 'u', 'l', 'l', '-', 'S', 't', 'a', 'c', 'k', ' ', 'D', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r', '.']

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)
    }, [])

    return (
        <>
        <div className="container home-page">
            <div className="text-zone">
                <h1>
                <span className={letterClass}>H</span>
                <span className={`${letterClass} _12`}>i</span>
                <span className={`${letterClass} _13`}>,</span>
                <br />
                <span className={`${letterClass} _14`}>I</span>
                <span className={`${letterClass} _15`}>'</span>
                <span className={`${letterClass} _16`}>m</span>
                <img src={LogoR}  id="LogoR" alt="developer" />
                <AnimatedLetters letterClass={letterClass} strArray={fnameArray} idx={17} />
                <img src={LogoTitle}  id="LogoTitle" alt="developer" />
                <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={18} />
                <br />
                <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={15} />
                </h1>
                <h2>Full-Stack Developer / Software Engineer / Coder</h2>
                <Link to="/cotact" className='flat-button'>CONTACT ME</Link>
            </div>
            <Logo />
        </div>
        <Loader type="pacman" />
        </>
    );

}

export default Home